export default {
  daysRemains_zero: 'Zbývá poslední den',
  daysRemains_one: 'Zbývá {{count}} den',
  daysRemains_few: 'Zbývají {{count}} dni',
  daysRemains_other: 'Zbývá {{count}} dní',

  daysExpired_one: 'Ukončeno před {{count}} dnem',
  daysExpired_few: 'Ukončeno před {{count}} dni',
  daysExpired_other: 'Ukončeno před {{count}} dni',

  waitSeconds_zero: 'sekund',
  waitSeconds_one: 'sekundu',
  waitSeconds_few: 'sekundy',
  waitSeconds_other: 'sekund',
};
