export default {
  title: 'Hlasování',
  Header: {
    title: 'Hlasování',
    createDraft: 'Založit nové hlasování',
    updateDraft: 'Upravit rozpracované hlasování',
  },

  List: {
    OncomingPolls: {
      title: 'Nadcházející hlasování',
    },
    ActivePolls: {
      title: 'Probíhající hlasování',
    },
    CompletedPolls: {
      title: 'Dokončená hlasování',
    },
    ArchivedPolls: {
      title: 'Archiv hlasování',
    },
  },

  Detail: {
    TabDetail: {
      title: 'Otázky v hlasování',
      Question: {
        label: 'Otázka',
      },
    },
    TabInfo: {
      description: 'Celé znění navrhovaného usnesení',
      descriptionExtra: 'Detailní popis',
      attachments: 'Přílohy',
      gallery: 'Fotogalerie',
    },
  },

  Question: {
    description: 'Celé znění navrhovaného usnesení',
    descriptionExtra: 'Detailní popis',
    attachments: 'Přílohy',
    gallery: 'Fotogalerie',
    Send: {
      buttonSend: 'Odeslat hlasování',
      notice: 'Upozornění!',
      enterCode: 'Zadejte kód',
      smsNotice:
        'Po odeslání hlasování vám přijde potvrzovací kód do SMS. Až po jeho vložení je hlasování považováno za právoplatné.',
      smsNoticeCode: 'Pro potvrzení odeslání hlasování, zadejte prosím kód z SMS, která Vám byla odeslána na číslo ',
      smsNoticeNumber: 'Pro potvrzení hlasování bude odeslána SMS s potvrzovacím kódem na číslo ',
      smsIsMissing: 'SMS Vám nedorazila?',
      smsSendAgain: 'Odeslat kód znovu',
      smsSendAgainTimer: 'Kód bude možné znovu odeslat za',
      step1NoticeText:
        'U některých z otázek jste nehlasovali. Pokud nyní potvrdíte, Váš hlas u těchto otázek se bude automaticky počítat jako “Zdržím se”',
      step1ConfirmButton: 'Potvrdit odeslání',
      step3ConfirmButton: 'Potvrdit',
      thankYouHeader: 'Děkujeme',
      thankYouText: 'Hlasování úspěšně odesláno',
      thankYouButton: 'Zpět na přehled hlasování',

      StepPhone: {
        title: 'Prosím přidejte svůj telefon',
        subtitle:
          'Pro potvrzení hlasování je potřeba potvrzení pomocí sms kódu, prosím přidejte ke svému profilu vaše telefonní číslo.',
        submit: 'Přidat číslo',
        error: 'Telefon se nepodařilo přidat, zkontrolujte zadané číslo.',
      },
    },
  },

  Card: {
    vote: 'Hlasovat',
    detail: 'Detail hlasování',
  },

  Results: {
    TabPoll: {
      title: 'Otázky v hlasování',
      tabTitle: 'Výsledky',
      questionLabel: 'Otázka',
    },

    TabVoterList: {
      tabTitle: 'Seznam hlasujících',
      unit: 'Jednotka',
      owner: 'Vlastník',
      Ticket: {
        generate: 'Vytisknout hlasovací lístek',
        generateAll: 'Vytisknout hlasovací lístky',
        generateResults: 'Stáhnout výsledky hlasování',
        error: 'Hlasovací lístek se nepodařilo vygenerovat, zkuste to prosím znovu.',
      },
      Vote: {
        completed: 'Odhlasováno',
        nonCompleted: 'Neodhlasováno',
      },
      Flash: {
        success: 'Hlasování bylo úspěšně odesláno.',
        error: 'Hlasování se nepodařilo odeslat, zkontrolujte prosím odpovědi a zkuste hlasování odeslat znovu.',
      },
      AdminVote: {
        title: 'Hlasování za jednotku {{unit}}',
        subtitle: 'Otázky bez odpovědi se automaticky počítají jako “Zdržím se”.',
        button: 'Zahlasovat za jednotku',

        attachment: 'Nahrát hlasovací lístek',
        filesAction: 'Pro dokončení hlasování {{count}} klikněte na tlačítko níže.',
        filesAction_one: 'Pro dokončení hlasování {{count}} klikněte na tlačítko níže.',
        submit: 'Hlasovat za jednotku',
      },
    },

    TabDetail: {
      tabTitle: 'Detail hlasování',
      Table: {
        voted: 'Odhlasováno',
        status: 'Stav hlasování',
        startDate: 'Začátek',
        endDate: 'Konec',
        evaluationDate: 'Vyhodnocení',
        evaluationMethod: 'Způsob vyhodnocení',
        PollState: {
          ongoing: 'Probíhající',
          published: 'Dokončené hlasování',
          waitingForResults: 'Čeká na vyhodnocení',
        },
      },
    },
  },
  emptyList: 'Nebyla nalezena žádná hlasování',

  AddPoll: {
    Header: {
      placeholder: 'Název hlasování',
      discardDraft: 'Zahodit hlasování',
      discardDraftModal: {
        confirm: 'Ano, zahodit',
        title: 'Opravdu zahodit hlasování',
        subtitle: 'Pokud zahodíte hlasování smažou se všechny již vytvořené otázky.',
      },
    },
    addQuestionLabel: 'Přidat otázku',
    addPollLabel: 'Pokračovat k vytvoření otázek',
  },

  AddQuestion: {
    label: 'Otázka',
    deleteText: 'Otázka byla vymazána',

    Form: {
      Fields: {
        Name: {
          label: 'Otázka',
          placeholder: 'Zadejte název otázky',
          required: 'Název otázky musí být vyplněný',
        },

        Description: {
          label: 'Celé znění navrhovaného usnesení',
          placeholder: 'Zadejte znění navrhovaného usnesení',
          required: 'Celé znění navrhovaného usnesení musí být vyplněné',
        },
        DescriptionExtra: {
          label: 'Popis otázky',
          placeholder: 'Zadejte popis otázky',
        },
        Attachments: {
          title: 'Nahrané přílohy',
          label: 'Nahrát přílohy',
          Delete: {
            success: 'Příloha byla úspěšně smazána.',
            error: 'Přílohu se nepodařilo smazat',
          },
        },
        Gallery: {
          title: 'Nahrané položky galerie',
        },

        EvaluateType: {
          label: 'Způsob vyhodnocení',
          Half: {
            label: 'Více než 50% procent',
          },
          CustomPercentage: {
            label: 'Vlastní',
          },
        },
        Submit: {
          label: 'Uložit otázku',
          successText: 'Otázka byla uložena',
          errorText: 'Otázku se nepodařilo uložit',
        },
      },
    },
  },

  PollSettings: {
    title: 'Nastavení hlasování',
    Form: {
      Fields: {
        StartAt: {
          placeholder: 'Vyberte datum kdy začne hlasování',
          label: 'Začátek hlasování',
        },
        EndAt: {
          placeholder: 'Vyberte datum kdy skončí hlasování',
          label: 'Konec hlasování',
        },
        PublicAt: {
          placeholder: 'Vyberte datum kdy budou výsledky publikovány',
          label: 'Zveřejnění výsledku hlasování',
        },
        Submit: {
          label: 'Publikovat hlasování',
        },
      },
    },
  },

  MissingContacts: {
    title: 'Doplnění kontaktních údajů',
    missingContactsCount: 'Počet vlastníků s chybějícími kontakty: {{profiles.length}}',
    addData: 'Přidat kontaktní údaje',
    publishPoll: 'Založit a publikovat hlasování',
    great: 'Skvěle!',
    noMissingContact: 'Všichni vlastníci mají vyplněný kontakt.',

    Flash: {
      Remove: {
        success: 'Hlasování bylo úspěšně smazáno.',
        error: 'Hlasování se nepodařilo smazat.',
      },
      Publish: {
        success: 'Hlasování bylo úspěšně publikováno.',
        error: 'Hlasování se nepodařilo publikovat, prosím zkontrolujte vámi zadané informace.',
      },
    },

    Card: {
      addButton: 'Přidat kontaktní údaje',
    },

    Modal: {
      title: 'Pozor',
      subtitle: 'Zadejte kontaktní údaje uživatele {{fullName}}.',
      infoText:
        'Některé osoby nemají vyplněné kontaktní informace. Aby byla zajištěna možnost hlasovat všem, zadejte kontakty nebo po založení hlasování vytiskněte hlasovací lístky. Hlasovacími lístky informujete vlastníky o probíhajícím hlasování i v případě, že na ně nebudete mít kontaktní údaje.',
      pollEditInfoText: 'Publikovaná hlasování již nelze upravovat.',
      confirm: 'Přesto pokračovat',
      confirmFullfiled: 'Pokračovat',
      back: 'Doplnit údaje',
    },

    Form: {
      successText: 'Kontakní údaje byly přidány',
      error: 'Údaje se nepodařilo aktualizovat, prosím zkontrolujte a zkuste znovu.',
      Fields: {
        Email: {
          label: 'Email',
          placeholder: 'Zadejte email',
        },
        Phone: {
          label: 'Telefon',
          placeholder: 'Zadejte telefonní číslo, např. +420 xxx xxx xxx',
        },
        Submit: {
          title: 'Uložit kontaktní informace',
        },
      },
    },
  },
};
